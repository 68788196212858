import { LockOutlined, Report } from '@mui/icons-material';
import { Box, Link, Stack, Typography } from '@mui/material';
import { Header, SupportMenu, UserMenu } from '../../components';
import { SUPPORT_PORTAL_LINK } from '../../constants';
interface UnauthorizedPageProps {
    logo: JSX.Element;
    driveLogo: JSX.Element;
    trainingGuideLink?: string;
    serviceBaseUrl: string;
}
export function UnauthorizedPage({ serviceBaseUrl, logo, trainingGuideLink, driveLogo }: UnauthorizedPageProps) {
    return (
        <>
            <Header serviceBaseUrl={serviceBaseUrl}>
                <Box justifySelf='start'></Box>
                <Box justifySelf='center'>{logo}</Box>
                <Box justifySelf='end'>
                    <Stack direction='row' spacing={1}>
                        <SupportMenu supportPortalLink={SUPPORT_PORTAL_LINK} trainingGuideLink={trainingGuideLink} />
                        <UserMenu />
                    </Stack>
                </Box>
            </Header>
            <Box sx={{ display: 'flex', height: 'calc(100% - 100px)', flexDirection: 'column' }}>
                <Stack alignSelf='center' alignItems='center' justifyContent='center' gap={4} sx={{ flex: 1 }}>
                    <Report sx={{ fontSize: 128, color: 'error.dark' }} />
                    <Stack direction='row' alignItems='center' gap={1}>
                        <LockOutlined sx={{ fontSize: 36 }} />
                        <Typography variant='h6' fontWeight='bold'>
                            Access Denied
                        </Typography>
                    </Stack>
                    <Typography variant='body1' color='text.secondary' textAlign='center'>
                        Your login does not have permission to view
                        <br /> this page. To get help accessing this page,
                        <br /> visit our&nbsp;
                        <Link href={SUPPORT_PORTAL_LINK} target='_blank' rel='noreferrer' color='inherit'>
                            application support portal
                        </Link>
                        .
                    </Typography>
                </Stack>
                <Stack direction='row' justifyContent='center'>
                    {driveLogo}
                </Stack>
            </Box>
        </>
    );
}
