import React from 'react';

export function useMenu() {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const openMenu = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const closeMenu = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const open = Boolean(anchorEl);

    return [anchorEl, openMenu, closeMenu, open] as const;
}
